// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,

   url: 'https://relaynavigatordev.isocptest.com',

  // url: 'localhost:4200',

   server: 'https://rnavapi-dev.isocptest.com/rnavapi',

  // server: 'http://localhost:3000/rnavapi',

  // server: 'https://blnyrtbhrk.execute-api.us-east-1.amazonaws.com/latest',

  okta_issuer: 'https://sso.int.verisk.com/oauth2/aus16fwz1gh8z7Ivh0h8',

  okta_client_id: '0oa1bd5l9v9xwT0UF0h8',

  okta_redirect_uri: 'https://relaynavigatordev.isocptest.com/login/callback'

  // okta_redirect_uri: 'http://localhost:4200/login/callback'

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
