import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from './environments/environment'
import { map } from 'rxjs/operators'
import SearchParams from './models/SearchParams'
import BatchParams from './models/BatchParams'


@Injectable({
    providedIn:'root'
})

export class ApiService {
    constructor(private http: HttpClient) {}

    // gets the full response from an individual document given an ID
    // used to display the details when the eye icon is clicked on the main page
    public getDocumentData(document_id:string) {
        let url = environment.server.concat(`/details/${document_id}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            }))
    }

    // generates a presigned url for document PDFs. 
    // takes the primary key and sort key as args
    public getPdfPreview(document_id:string, letter_id:string) {
        let url = environment.server.concat(`/document/${document_id}/${letter_id}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    public getPdfview(document_id:string) {
        let url = environment.server.concat(`/document/${document_id}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }


    // hard delete. frontend logic is currently commented out
    // use this endpoint when hard deleting, as DynamoDB's non-relational structure means a delete requires deletion from two tables
    public deleteEntry(document_id:string, letter_id:string, date:string, time:string, batch_id:string, batch_file_name:string) {
        let url = environment.server.concat("/delete")
        url = url.concat(`/${document_id}/${letter_id}/${date}/${time}/${batch_id}/${batch_file_name}`)

        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    // part of hard delete logic. frontend logic is commeneted out
    // updates the duplicates array that each entry in the DB has
    public updateDuplicateEntries(document_id:string, document_to_delete:string) {
        let url = environment.server.concat(`/updateDuplicates/${document_id}/${document_to_delete}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    // soft delete logic
    // adds a DB column called "deleted" with a timestamp of the deletion
    // updates the 'reviewed' column to say deleted
    public softDelete(document_id:string, username:string) {
        const deleted_user = username.replace(/ /g, "%20");
        let url = environment.server.concat(`/softDelete?document_id=${document_id}&&deleted_user=${deleted_user}`)

        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    //Retigger the document payload 
    //pushed the doc payload to SQS
    public retriggerEmail(element:any) {
        
        const doc_payload = element.doc_payload.replace(/ /g, "%20");
        const sort_key = element.sort_key.replace(/ /g, "%20");
        const customer_cd = element.customer_cd;

        let url = environment.server.concat(`/retrigger?sort_key=${sort_key}&&customer_cd=${customer_cd}&&doc_payload=${doc_payload}`)

        console.log(url);
        return this.http.put<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    // main get endpoint for batches
    // url updated based on searchParams
    public getBatches(batch_identifier?:string|null, batch_file_name?:string|null, date_of_upload?:string|null, search_params?:BatchParams) {
        
        let url = environment.server.concat('/batch_search')
        if (batch_identifier != null && batch_file_name != null && date_of_upload != null) {
            url = url.concat(`/?batch_identifier=${batch_identifier}&&batch_file_name=${batch_file_name}&&date_of_upload=${date_of_upload}&&`)
        }

        else {
            url = url.concat("/?")
        }


        if (search_params != undefined) {
            if (search_params.batch_file_name != null && search_params.batch_file_name != '') {
                url = url.concat(`batch_file_filter=${search_params.batch_file_name}&&`)
            }

            if (search_params.reviewed != null) {
                url = url.concat(`reviewed=${search_params.reviewed}&&`)
            }

            if (search_params.date_of_upload != null && search_params.date_of_upload != '') {
                url = url.concat(`date_filter=${search_params.date_of_upload}`)
            }
        }

        return this.http.get<any>(url, {observe: 'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }


    // updates the 'reviewed' column in the backend to either true or false
    public updateReview(document_id:string, date:string, updatedValue:string, batch_id:string, batch_file_name:string) {
        let url = environment.server.concat('/updateReview')
        date = date.replace(/ /g, "%20")

        url = url.concat(`/?document_id=${document_id}&&date=${date}&&updated_value=${updatedValue}&&batch_id=${batch_id}&&batch_file_name=${batch_file_name}`)


        return this.http.put<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    public updateFailureReview(customer_cd:string, sort_key:string) {
        sort_key = sort_key?.replace(/ /g, "%20")
        let url = environment.server.concat('/updateFailureReview')
        console.log(url)
        url = url.concat(`/${customer_cd}/${sort_key}`)
        return this.http.put<any>(url, {observe:'response'}).pipe(
            map((res:any) => {
                return JSON.stringify(res.body)
            })
        )
    }

    // main get endpoint for document mode
    // updates url based on given search parameters
    public getEntries(document_id?:string|null, date?:string|null, search_params?:SearchParams) {
        let url = environment.server.concat('/document_search')
        date = date?.replace(/ /g, "%20")
        console.log(date)
        if (document_id != null && date != null) {
            url = url.concat(`/?last_seen_id=${document_id}&&last_seen_date=${date}&&`)
        }

        else {
            url = url.concat("/?")
        }


        if (search_params != undefined) {
            if (search_params.claim_number != null && search_params.claim_number != '') {
                url = url.concat(`claim_number=${search_params.claim_number}&&`)
            }

            if (search_params.first_name != null && search_params.first_name != '') {
                url = url.concat(`first_name=${search_params.first_name}&&`)
            }

            if (search_params.last_name != null && search_params.last_name != '') {
                url = url.concat(`last_name=${search_params.last_name}&&`)
            }

            if (search_params.letter_id != null && search_params.letter_id != '') {
                url = url.concat(`letter_id=${search_params.letter_id}&&`)
            }

            if (search_params.batch_id != null && search_params.batch_id != '') {
                url = url.concat(`batch_id=${search_params.batch_id}&&`)
            }

            if (search_params.date_of_letter != null && search_params.date_of_letter != '') {
                let date_of_letter = search_params.date_of_letter.replace(/ /g, "%20")
                url = url.concat(`date_of_letter=${date_of_letter}&&`)
            }

            if (search_params.date_of_upload != null && search_params.date_of_upload != '') {
                url = url.concat(`date_of_upload=${search_params.date_of_upload}`)
            }   

            if (search_params.isSearchingDuplicates == true) {
                url=url.concat(`duplicates=`)
                url = url.concat(`${search_params.duplicates},`)
                /*for (let i = 0; i < search_params.duplicates?.length!; i++) {
                    url = url.concat(`${search_params.duplicates},`)
                }*/
            }

        }

        // console.log(url)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                //console.log(response.body)
                return response.body
            })
        )
    }

 }
