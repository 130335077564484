import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentOptions } from './modal.options';

@Component({
  selector: 'iso-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnInit {

  @Input() modalContentOptions: ModalContentOptions;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  getClass(target: string): any {
    const options = this.modalContentOptions;
    switch (target) {
      case 'button-close': {
        if (options.isError) {
          return 'iso-close-error flat-button';
        } else if (options.isWarning || options.isInfo) {
          return 'iso-close-warning flat-button';
        } else {
          return 'flat-button';
        }
      }
      case 'button-action': {
        if (options.isWarning) {
          return 'iso-warning-action flat-button';
        } else {
          return 'flat-button';
        }
      }
      case 'modal-header': {
        if (options.isError) {
          return 'modal-header iso-error-header';
        } else if (options.isWarning) {
          return 'modal-header iso-warning-header';
        } else if (options.isInfo) {
          return 'modal-header iso-info-header';
        } else {
          return 'modal-header';
        }
      }
      default:
        break;
    }
  }

}
