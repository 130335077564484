import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from 'src/api.service';
import SearchParams from '../../models/SearchParams';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  constructor(     
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<DeleteComponent>,
    private apiService: ApiService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) { }

  public deleteParams = <SearchParams>{};

  

  ngOnInit(): void {
    console.log(this.data)
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public async deleteEntry() {

    const loggedinUser = await this._oktaAuth.getUser();
    //console.log(loggedinUser.name);
    // soft deletes
    await this.apiService.softDelete(this.data.document_id, loggedinUser.name!).subscribe((data) => {
      console.log('soft deleted')
      this.notifyParent.emit(this.data.document_id)
      this.dialogRef.close()
    })


    // THE COMMENTED LOGIC BELOW IS THE HARD DELETE. COMMENT THE LINES ABOVE AND UNCOMMENT BELOW FOR HARD DELETE FUNCTIONALITY



    // let split_date = this.data.date_of_upload.split(" ")
    // if (this.data.duplicates.length-1 == 0) {
    //   await this.apiService.deleteEntry(this.data.document_id, this.data.letter_id, split_date[0], split_date[1], this.data.batch_id, this.data.batch_file_name).subscribe((data) => {
    //     this.notifyParent.emit(this.data.document_id)
    //     this.dialogRef.close()
    //     return
    //   })
    // }
    // else {
    // this.deleteParams.beneficiary_name = this.data.name
    // this.deleteParams.claim_number = this.data.claim_number
    // this.deleteParams.letter_id = this.data.letter_id
    // let duplicate_list:any[] = []
    // let updated_duplicates = this.data.duplicates - 1;

    
    // await this.apiService.deleteEntry(this.data.document_id, this.data.letter_id, split_date[0], split_date[1], this.data.batch_id, this.data.batch_file_name).subscribe((data) => {
    //   var counter = 0;

    //   for (let i = 0; i < this.data.duplicates.length; i++) {
    //     this.apiService.updateDuplicateEntries(this.data.duplicates[i], this.data.document_id).subscribe((data) => {
    //       counter++
    //       if (counter == this.data.duplicates.length - 1) {
    //         this.notifyParent.emit(this.data.document_id)
    //         this.dialogRef.close()
    //         return
    //       }})}

    //     })
    //   }
        

  }

}
