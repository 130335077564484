import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import { ApiService } from 'src/api.service';
import {MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.css']
})
export class DocumentDetailsComponent implements OnInit {

  constructor(
    private route:ActivatedRoute, 
    private apiService:ApiService,
     public dialog: MatDialogModule,
     public dialogRef: MatDialogRef<DocumentDetailsComponent>,
     @Inject(MAT_DIALOG_DATA) public data: string, 
     ) { }

  public document_id:string;
  public letter_id:string;
  public displayedColumns: string[] = ['category', 'value'];
  public dataSource:any;

ngOnInit(): void {
  let temp_data:any
  temp_data = this.data

  let fields = temp_data.items.Items[0]

  let display_data = []

  // since each document gives different data, we have to use these conditionals to see what data we're getting
  // depending on the data we get, add it to the display_data array, to be shown in table form on the UI
  
  if (fields['beneficiary_name'] != undefined) {
    display_data.push({ category: 'Beneficiary Name', value: fields['beneficiary_name'] })
  }

  if (fields['claim_number'] != undefined) {
    display_data.push({ category: 'Claim Number', value: fields['claim_number'] })
  }

  if (fields['letter_id'] != undefined) {
    display_data.push({ category: 'Letter ID', value: fields['letter_id'] })
  }

  if (fields['date_of_incident'] != undefined) {
    display_data.push({ category: 'Date of Incident', value: fields['date_of_incident'] })
  }

  if (fields['date_of_letter'] != undefined) {
    display_data.push({ category: 'Date of Letter', value: fields['date_of_letter'] })
  }

  if (fields['date_of_upload'] != undefined) {
    let date = new Date(fields['date_of_upload'])
    let dateString = date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});    
    display_data.push({ category: 'Date of Upload', value: dateString })
  }

  if (fields['case_identification_number'] != undefined) {
    if (fields['case_identification_number'].includes("Case Identification Number:")) {
      display_data.push({ category: "Case ID Number", value: fields['case_identification_number'].substring(27) })
    }
    else {
      display_data.push({ category: "Case ID Number", value: fields['case_identification_number'] })

    }
  }


  if (fields['additional_information'] != undefined) {


    if (fields['additional_information']['Insurer Policy Number'] != undefined) {
      display_data.push({ category: 'Insurer Policy Number', value: fields['additional_information']['Insurer Policy Number']['S'] })
    }

    if (fields['additional_information']['Medicare ID'] != undefined) {
      display_data.push({ category: 'Medicare ID', value: fields['additional_information']['Medicare ID']['S'] })
    }

    if (fields['additional_information']['CRC Recovery ID Number'] != undefined) {
      display_data.push({ category: 'CRC Recovery ID', value: fields['additional_information']['CRC Recovery ID Number']['S'] })
    }

  
  }

  if (fields['bucket_name'] != undefined) {
    display_data.push({ category: 'Bucket Name', value: fields['bucket_name'] })
  }


  if (fields['folder_name'] != undefined) {
    display_data.push({ category: 'Folder', value: fields['folder_name'] })
  }

  if (fields['duplicates'] != undefined) {
    let length = fields['duplicates'].length
    display_data.push({ category: 'Duplicates', value: length - 1 })
  }
  
  if (fields['document_control_number'] != undefined) {
    if (fields['document_control_number'].includes("Document Control Number:")) {
      display_data.push({ category: 'Document Control #', value: fields['document_control_number'].substring(24)})
    }
    else {
      display_data.push({ category: 'Document Control #', value: fields['document_control_number']})
    }
  }


  if (fields['document_id'] != undefined) {
    display_data.push({ category: 'Document ID', value: fields['document_id'] })
  }

  if (fields['batch_id'] != undefined) {
    display_data.push({category: 'Batch ID', value: fields['batch_id']})
  }

  if (fields['batch_file_name'] != undefined) {
    display_data.push({ category: 'Batch File', value: fields['batch_file_name'] })
  }

    this.dataSource = display_data
  }
}
