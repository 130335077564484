<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; justify-content: center;">
      
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let element"> {{element.category}} </td>
    </ng-container>
  
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> <span class="document" (click)="getDetails(element.value)">{{element.value}}</span> </td>
    </ng-container>
        
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  