import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { AppComponent } from './app.component';
import { LettersTableComponent } from './letters-table/letters-table.component';
import { DocumentDetailsComponent } from './document-details/document-details.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

const routes: Routes = [
  { path: '', redirectTo: 'entries', pathMatch: 'full' },
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'entries', component: LettersTableComponent, canActivate: [OktaAuthGuard]},
  { path: 'entries/:document_id/:letter_id', component: DocumentDetailsComponent},
  { path: 'email-template', loadChildren: () => import('./modules/email-template/email-template.module').then(m => m.EmailTemplateModule) },
  { path: 'sent-emails', loadChildren: () => import('./modules/sent-emails/sent-emails.module').then(m => m.SentEmailsModule) },
  { path: 'email-failures', loadChildren: () => import('./modules/email-failures/email-failures.module').then(m => m.EmailFailuresModule) },
  { path: 'deleted-letters', loadChildren: () => import('./modules/deleted-letters/deleted-letters.module').then(m => m.DeletedLettersModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
