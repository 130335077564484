<div class="header">
    <div class="menu matItemMenu">
        <div mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="menu-icon hamberger-menu">menu</mat-icon>
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item routerLink='/entries'   routerLinkActive="active">
                <mat-icon class="white-icon">home</mat-icon>
                <span>Home</span>
            </button>
            <button mat-menu-item routerLink='/email-template' routerLinkActive="active">
                <mat-icon class="white-icon">email</mat-icon>
                <span>Email Template Management</span>
            </button>
            <button mat-menu-item routerLink='/sent-emails' routerLinkActive="active">
                <mat-icon class="white-icon">contact_mail</mat-icon>
                <span>Sent Emails</span>
            </button>
            <button mat-menu-item routerLink='/email-failures' routerLinkActive="active">
                <mat-icon class="white-icon">sms_failed</mat-icon>
                <span>Email Failures</span>
            </button>
            <button mat-menu-item routerLink='/deleted-letters' routerLinkActive="active">
                <mat-icon class="white-icon">folder_delete</mat-icon>
                <span>Deleted Letters</span>
            </button>
        </mat-menu>
    </div>
    <div class="menu logoMenu">
        <img class="logo" src="assets/verisk_logo.svg">
    </div>
</div>
<!-- LOADING ICON -->
<div *ngIf="loading" class="loading">
    <img src="assets/load_gif.gif">
</div>